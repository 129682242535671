<template lang="pug">
include ../../../../configs/template
form.row
  div.col-12
    +field-validation('body.additional_info.number', 'number', '["required"]')(:readonly="isCheckDocument")
  div.col-12
    +select-validation('body.additional_info.position','listMedicalPosition', 'position', 'nameLang', '["required"]')
  div(v-if="isCheckDocument").col-12
    +select-validation('body.additional_info.limitation', 'listLimitation', 'limitation', 'nameLang', '["required"]')
  div.col-12
    +select-validation('body.additional_info.medical_institution', 'ListMedicalInstitutions', 'medicalInstitution', '"value"', '["required"]')(@input="getListDoctor")
  div.col-12
    +select-validation('body.additional_info.doctor', 'listDoctor', 'doctor', '"FIO"', '["required"]')
  div.col-sm-12.col-md-6
    +date-picker-validation('body.additional_info.date_start','dateIssue','dateObjectStart','["required", "minValue", "maxValue"]', 'isCheckDocument','!isCheckDocument')(
      :readonly="isCheckDocument")
  div(v-if="isCheckDocument").col-sm-12.col-md-6
    +date-picker-validation('body.additional_info.date_end','dateTermination','dateObjectEnd','["required", "minValue"]')
  div(v-if="isCheckDocument").col-12
    FileDropZone(ref="mediaContent")
  div.col-12
    v-btn(v-if="isCheckDocument" color="success" @click="saveDocument" :loading="isLoadingSave").mr-4 {{$t('save')}}
    v-btn(v-else color="success" @click="checkDocument" :loading="isLoading") {{$t('btn.checkDocument')}}
    v-btn(v-if="isCheckDocument" color="secondary" @click="clearForm") {{$t('btn.clear')}}

</template>
<script>
import { verificationSC } from '@/mixins/validationRules'
import { mapActions, mapGetters, mapState } from 'vuex'
import { clearBody } from '@/mixins/main'

const initBody = () => (
  {
    additional_info: {
      number: null,
      position: null,
      date_end: null,
      date_start: null,
      doctor: null,
      medical_institution: null
    }
  }
)

export default {
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue')
  },
  data () {
    return {
      body: initBody(),
      isCheckDocument: false,
      listDoctor: [],
      isLoading: false
    }
  },
  computed: {
    ...mapGetters(['doctorsByMedInstitutionID']),
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      listMedicalPosition: state => state.directory.positionMedical,
      listLimitation: state => state.directory.limitations,
      ListMedicalInstitutions: state => state.directory.medInstitution,
      isLoadingSave: state => state.documents.isLoading
    }),
    dateObjectStart () {
      return this.body.additional_info.date_start ? new Date(this.body.additional_info.date_start) : null
    },
    dateObjectEnd () {
      return this.body.additional_info.date_end ? new Date(this.body.additional_info.date_end) : null
    }
  },
  validations () { return verificationSC.medical(this) },
  methods: {
    ...mapActions(['getInfoAboutDocumentForDigitizationSC']),
    saveDocument () {
      const { statementId } = this.$route.params
      if (this.$v.$invalid) return this.$v.$touch()
      let data = {
        body: { ...this.body },
        media: {
          name: 'MedicalDoc',
          files: [...this.$refs.mediaContent.filesArray]
        }
      }
      this.$emit('saveDocument', { id: statementId, data, method: 'setDigitizationDocumentSC' })
    },
    async checkDocument () {
      if (this.$v.$invalid) return this.$v.$touch()
      this.isLoading = true
      const { statementId } = this.$route.params
      const response = await this.getInfoAboutDocumentForDigitizationSC({ id: statementId, body: { additional_info: { ...clearBody(this.body.additional_info) } } })
      if (response) {
        this.$notification.success('notify.success.checkDocument')
        this.isCheckDocument = true
      }
      this.isLoading = false
    },
    clearForm () {
      this.isCheckDocument = false
      this.body = initBody()
      this.$v.$reset()
    },
    getListDoctor (id) {
      this.listDoctor = this.doctorsByMedInstitutionID(id)
    }
  }

}
</script>
